import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {FlexLayoutModule} from '@angular/flex-layout';

// SERVICES
import {AuthGuard} from './services/auth/auth.guard';
import {AppLoaderService} from './services/app-loader/app-loader.service';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {EmailService} from './services/email.service';
import {HeaderComponent} from '../views/home/header/header.component';
import {FooterComponent} from '../views/home/footer/footer.component';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {WINDOW_PROVIDERS} from './helpers/window.helper';
import {SharedDirectivesModule} from './directives/shared-directives.module';
import {SharedComponentsModule} from './components/shared-components.module';
import {SharedMaterialModule} from './shared-material.module';
import {ErrorDialogComponent} from './components/error-dialog.component';
import {ScrollToDirective} from './directives/scroll-to.directive';
import {PricingsComponent} from '../views/home/pricings/pricings.component';

const classesToInclude = [
  HeaderComponent,
  FooterComponent,
  ScrollToDirective
]

@NgModule({
    declarations: [
        classesToInclude,
        ErrorDialogComponent,
        PricingsComponent
    ],
    exports: [
        classesToInclude,
        PricingsComponent,
    ], imports: [CommonModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    MatListModule,
    MatIconModule,
    ReactiveFormsModule,
    SharedDirectivesModule,
    SharedComponentsModule,
    SharedMaterialModule,
    FlexLayoutModule, NgOptimizedImage], providers: [
        AuthGuard,
        AppLoaderService,
        EmailService,
        WINDOW_PROVIDERS,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class SharedModule { }
