import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {Observable, of} from 'rxjs';
import { AppLoaderComponent } from './app-loader.component';
import {isPlatformBrowser} from '@angular/common';

@Injectable()
export class AppLoaderService {
  dialogRef: MatDialogRef<AppLoaderComponent>;
  constructor(private dialog: MatDialog, @Inject(PLATFORM_ID) private platformId: Object) { }

  public open(title: string = 'Please wait'): Observable<boolean> {
    if (isPlatformBrowser(this.platformId)) {
      this.dialogRef = this.dialog.open(AppLoaderComponent, {disableClose: true, backdropClass: 'light-backdrop'});
      this.dialogRef.updateSize('200px');
      this.dialogRef.componentInstance.title = title;
      return this.dialogRef.afterClosed();
    } else {
      return of (true);
    }
  }

  public close() {
    if(this.dialogRef)
      this.dialogRef.close();
  }
}
