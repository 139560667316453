import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {LocalStorageService} from '../local-storage.service';
import {AuthenticationService} from '../authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  private isAuthenticated = false;

  constructor(private router: Router, private storageService: LocalStorageService,  private authenticationService: AuthenticationService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const token = this.storageService.getItem('token');
    if (token) {
        return true;
    }

    // Store the attempted URL for redirecting after login
    this.authenticationService.redirectUrl = state.url;
    this.router.navigate(['/login']);
    return false;
  }
}
