import {Component, HostBinding, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {WINDOW} from '../../../shared/helpers/window.helper';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../../shared/services/local-storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  isFixed;
  loggedIn = false;
  loggedInEmail = '';
  private loggedInSub: Subscription;
  private loggedInEmailSub: Subscription;

  @HostBinding('class.menu-opened') menuOpened = false;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    @Inject(PLATFORM_ID) private platformId: Object,
    private authService: AuthenticationService,
    private router: Router,
    private storageService: LocalStorageService,
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.loggedInSub = this.authService.isLoggedIn.subscribe((loggedIn) => {
        this.loggedIn = loggedIn;
      });
      this.loggedInEmailSub = this.authService.loggedInEmail.subscribe((loggedInEmail) => {
        this.loggedInEmail = loggedInEmail;
      });
    }

    const token = this.storageService.getItem('token');
      if (token) {
          this.loggedIn = true;
    }

    const email = this.storageService.getItem('email');
    if (token !== null) {
      this.loggedInEmail = email;
    }
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (isPlatformBrowser(this.platformId)) {
      const offset = this.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
      this.isFixed = offset > 10;
    }
  }

  ngOnDestroy() {
    if (this.loggedInSub) {
      this.loggedInSub.unsubscribe();
    }
    if (this.loggedInEmailSub) {
      this.loggedInEmailSub.unsubscribe();
    }
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/']).then(() => {
      window.location.reload();
    });
  }
}
