import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {map} from 'rxjs/operators';
import {catchError, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  private url = 'https://mailthis.to/NotionHabitHeroes';

  constructor(private http: HttpClient) { }

  SendEmail(input: any) {
    return this.http.post(this.url, input).pipe(
      map((response: any) => {
        if (response) {
          return response;
        }
      }),
      catchError((error) => {
        if (error) {
          return of(error);
        }
      })
    );
  }
}
