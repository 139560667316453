import {ClassProvider, FactoryProvider, InjectionToken, Injectable, Inject, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

export function windowFactory(platformId: Object): any {
  if (isPlatformBrowser(platformId)) {
    return window;
  }
  // Return a mock window object or `null` if you don't need to support SSR
  return null;
}

export const WINDOW = new InjectionToken<Window | null>('WindowToken');

export abstract class WindowRef {
  abstract get nativeWindow(): Window | null;
}

@Injectable()
export class BrowserWindowRef extends WindowRef {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    super();
  }

  get nativeWindow(): Window | null {
    return windowFactory(this.platformId);
  }
}

const browserWindowProvider: ClassProvider = {
  provide: WindowRef,
  useClass: BrowserWindowRef
};

export const windowProvider: FactoryProvider = {
  provide: WINDOW,
  useFactory: windowFactory,
  deps: [PLATFORM_ID]
};

export const WINDOW_PROVIDERS = [
  browserWindowProvider,
  windowProvider
];
